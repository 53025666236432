<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="d-flex align-center justify-space-between">
                <h1 class="text-h4" style="flex-grow: 1">Arrangementer</h1>
                <v-btn color="primary" to="/arrangement/ny">
                    Nytt arrangement
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
                <v-btn v-if="canSync" color="grey lighten-3" v-on:click="office365" class="ml-2"> Lagre i Office365 </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-toolbar class="mt-6 d-flex" color="grey lighten-4" elevation="4">
                <div class="mr-4">
                    <v-select v-model="status" :items="statuser" item-text="navn" item-value="id" label="Status" hide-details></v-select>
                </div>
            </v-toolbar>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="aktiviteter"
                    :custom-sort="customSort"
                    sort-by="start"
                    :sort-desc="true"
                    :items-per-page="-1"
                    must-sort
                    hide-default-footer
                >
                    <template v-slot:item.tittel="{ item }">
                        <router-link :to="'/arrangement/' + item.id + '/rediger'" class="text-decoration-none">{{ item.tittel }}</router-link>
                    </template>

                    <template v-slot:item.start="{ item }"> {{ item.start | format('DD.MM.YYYY HH:mm') }} </template>
                    <template v-slot:item.type="{ item }"> {{ item.type.navn }} </template>
                    <template v-slot:item.moterom="{ item }"> {{ item.moterom ? item.moterom.navn : '' }} </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
    name: 'Activities',
    data() {
        return {
            ready: false,
            loading: false,

            headers: [
                { text: 'Tittel', value: 'tittel', sortable: false },
                { text: 'Type', value: 'type', sortable: false },
                { text: 'Når', value: 'start', sortable: true },
                { text: 'Møterom', value: 'moterom', sortable: false },
                /*Påmelding,
                Påmeldte deltakere
                Maks. deltakere
                Publiser*/
            ],
            aktiviteter: [],
            alleAktiviteter: [],

            status: '',
            statuser: [
                { id: '', navn: 'Kommende' },
                { id: 'gjennomførte', navn: 'Gjennomførte' },
                { id: 'alle', navn: 'Alle' },
            ],
        };
    },
    computed: {
        ...mapGetters('api', ['user']),
        canSync: function () {
            return this.user.id == '368a1b32-43cb-47c0-8013-8512f487239a'; /// todo
        },
    },
    watch: {
        status: function () {
            if (this.ready && !this.loading) {
                this.update();
            }
        },
    },
    filters: {
        format: function (value, format) {
            return value.format(format);
        },
    },
    /**
     * created
     */
    created: async function () {
        await this.update();
    },
    methods: {
        ...mapActions('api', ['request', 'formatActivity']),

        /**
         * update
         */
        update: async function () {
            try {
                this.loading = true;
                this.metoder = [];
                const response = await this.request({
                    method: 'get',
                    url: '/aktivitet',
                });

                if (response && Array.isArray(response)) {
                    const alleAktiviteter = [];
                    for (let aktivitet of response) {
                        if (aktivitet.type.id != 'SORGGRUPPE') {
                            alleAktiviteter.push(await this.formatActivity(aktivitet));
                        }
                    }

                    this.alleAktiviteter = alleAktiviteter;
                }

                this.filter();
                this.loading = false;
                this.ready = true;
            } catch (e) {
                console.log(e);
            }
        },

        /**
         * filter
         */
        filter: function () {
            const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

            const aktiviteter = [];
            for (const aktivitet of this.alleAktiviteter) {
                if (this.status == 'alle' || (this.status == '' && aktivitet.start > today) || (this.status == 'gjennomførte' && aktivitet.start <= today)) {
                    aktiviteter.push(aktivitet);
                }
            }
            this.aktiviteter = aktiviteter;
        },

        /**
         * customSort
         */
        customSort: function (items, index, isDesc) {
            if (index == 'start') {
                items.sort((a, b) => {
                    //let sortedArray = array.sort((a, b) => a.valueOf() - b.valueOf()

                    if (!isDesc[0]) {
                        return a[index].valueOf() - b[index].valueOf();
                    } else {
                        return b[index].valueOf() - a[index].valueOf();
                    }
                });
                /*items.sort((a, b) => {
                    if (!isDesc[0]) {
                        return a[index].localeCompare(b[index], 'no', {numeric: true, sensitivity: 'base'});
                    } else {
                        return b[index].localeCompare(a[index], 'no', {numeric: true, sensitivity: 'base'});
                    }
                });*/
            }
            return items;
        },

        /**
         * open
         */
        open: function (/*aktivitet*/) {
            //this.$router.push({ name: '/sorg/gruppe', params: { id: gruppe.id } });
        },

        /**
         * office365
         */
        office365: async function () {
            try {
                this.loading = true;
                this.metoder = [];
                const response = await this.request({
                    method: 'get',
                    url: '/aktivitet/office365',
                });

                if (response && Array.isArray(response)) {
                    for (let aktivitet of response) {
                        aktivitet = await this.formatActivity(aktivitet);
                        console.log(
                            aktivitet.start.format('DD.MM.YYYY HH:mm') +
                                ' - ' +
                                aktivitet.slutt.format('HH:mm') +
                                ', ' +
                                aktivitet.tittel +
                                ', ' +
                                aktivitet.eventId
                        );
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },

        /**
         * classRow
         */
        classRow() {
            return 'v-data-table-item--link';
        },
    },
};
</script>
