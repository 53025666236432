var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-space-between",attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h4",staticStyle:{"flex-grow":"1"}},[_vm._v("Arrangementer")]),_c('v-btn',{attrs:{"color":"primary","to":"/arrangement/ny"}},[_vm._v(" Nytt arrangement "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1),(_vm.canSync)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"grey lighten-3"},on:{"click":_vm.office365}},[_vm._v(" Lagre i Office365 ")]):_vm._e()],1)],1),_c('v-row',[_c('v-toolbar',{staticClass:"mt-6 d-flex",attrs:{"color":"grey lighten-4","elevation":"4"}},[_c('div',{staticClass:"mr-4"},[_c('v-select',{attrs:{"items":_vm.statuser,"item-text":"navn","item-value":"id","label":"Status","hide-details":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.aktiviteter,"custom-sort":_vm.customSort,"sort-by":"start","sort-desc":true,"items-per-page":-1,"must-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.tittel",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":'/arrangement/' + item.id + '/rediger'}},[_vm._v(_vm._s(item.tittel))])]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format")(item.start,'DD.MM.YYYY HH:mm'))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type.navn)+" ")]}},{key:"item.moterom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moterom ? item.moterom.navn : '')+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }